import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  confirmDates: {},
};

const SspSliceLocal = createSlice({
  initialState,
  name: "ssp-local",
  reducers: {
    setConfirmDate: (state, { payload }) => {
      state.confirmDates = payload;
    },
  },
});

export const {
  setConfirmDate,
} = SspSliceLocal.actions;

export default SspSliceLocal.reducer;
