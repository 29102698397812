import { createSlice } from "@reduxjs/toolkit";

import DdcData from "../components/walletHero/ddcData";
import { trimObjectValues } from "../utils/formik/objectUtils";

const initialState = {
  ddcData: new DdcData(),
};

const AuthenticationSliceDdc = createSlice({
  initialState,
  name: "registration",
  reducers: {
    setDdcData: (state, { payload }) => {
      state.ddcData = {
        ...state.ddcData,
        ...trimObjectValues(payload),
      };
    },
  },
});

export const {
  setDdcData,
} = AuthenticationSliceDdc.actions;

export default AuthenticationSliceDdc.reducer;
